import { AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, ComponentFactoryResolver, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { LoaderService } from 'app/_services/loading.service';


@Component({
    selector     : 'fuse-progress-bar',
    templateUrl  : './progress-bar.component.html',
    styleUrls    : ['./progress-bar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseProgressBarComponent  {

    @Input() isLoading: boolean = false;

     loading: boolean;

  constructor(private loaderService: LoaderService) {

    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });

  }
  ngOnInit() {
  }


   

}
