<div class=" maria-integration text-center">
  <img mat-dialog-title [ngClass]="{'error' :data.mode === 'inactive' ,'active' :data.mode === 'active' }" [src]="data.image"  />
  <div mat-dialog-content  [ngClass]="{'error' :data.mode === 'inactive' ,'active' :data.mode === 'active' }">
    <p>{{data.msg | translate}} {{data?.error | translate}} </p>

    </div>

    <div mat-dialog-actions>
      <button   mat-raised-button class="btn-mat  btn-sm  mb-16" 
       [ngClass]="{'popError' :data.mode === 'inactive' ,'popActive' :data.mode === 'active' }"
         [mat-dialog-close]="data" cdkFocusInitial   >
        {{'COMMUN.OK' | translate}}
      </button>

     
    </div>
    </div>