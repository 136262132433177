<div class="navbar-header  background-Jobzio" >

    <div class="logo" >
      <a  [routerLink]="['']"  >
        <img  class="logo-icon" src="assets/images/logos/jobzioo.png">
      </a>
    </div>

</div>

<div class="navbar-content  background-Jobzio" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}"
[ngClass]="fuseConfig.layout.navbar.primaryBackground">
    <fuse-navigation layout="vertical" [role]="role"></fuse-navigation>
</div>

 <div class="footer-footer">
   <span>  {{date}} </span>
   <span>  Jobzioo </span> 
 </div>


