import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { User } from 'app/features/integration/usersIntegration/models/user.model';
import { UsersService } from 'app/features/integration/usersIntegration/services/users.service';
import { AuthService } from 'app/_services/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    currentUser ;
    unsubscribeAll: Subject<any>;
  
    constructor(
        private authservice: AuthService ,
        private router: Router,
       
    ) { 
        this.unsubscribeAll = new Subject();
         

        
    }

    canActivate() {
       
       // //////console.log(need_terms);
        
        if ( !this.authservice.IsloggedIn()  ) {                 
            return true;
            
        }


        else {        
            this.router.navigate(['/auth/login']);
            return false;
        }
    }
}
