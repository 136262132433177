

export const locale = {
    lang: 'de',
    data: {
        ICON: {
            REFRESH: 'Aktualisieren',
            ADD_USER: 'Benutzer hinzufügen',
            EDIT_USER: 'Benutzer bearbeiten',
            VIEW_USER: 'Benutzerdetails',
            VIEW_RECRUITING: 'Rekrutierungsdetails',
            VIEW_JOS: 'Angebotsdetails',
            VIEW_JO: 'Angebotsdetails',
            COPY_JO: "Angebot kopieren",
            CANCEL_JO: "Angebot stornieren",
            EXPIRE_JO: "Angebot ablaufen lassen",
            EDIT_JO: "Angebot bearbeiten",
            ADD_WHISHLIST: 'Zur Wunschliste hinzufügen',
            REMOVE_WHISHLIST: 'Von der Wunschliste entfernen',
            INVITE_CANDIDATE: 'Kandidaten einladen',
            CANCEL_INVITATION: 'Einladung stornieren',
            VIEW_CANDIDATE: 'Kandidatendetails',
            VIEW_COMMENT: 'Kommentar anzeigen',
            APPROVE_DOCUMENT: 'Dokument genehmigen',
            DELETE_DOCUMENT: 'Dokument löschen',
            ADD_DOCUMENT: 'Dokument hinzufügen',
            ACCEPT_APPLICATION: 'Bewerbung annehmen',
            DECLINE_APPLICATION: 'Bewerbung ablehnen',
            ACCEPT_PROPOSITION: 'Vorschlag annehmen',
            DECLINE_PROPOSITION: 'Vorschlag ablehnen',
            ACCEPT_MATCH: 'Übereinstimmung akzeptieren',
            DECLINE_MATCH: 'Übereinstimmung ablehnen',
            ACCEPT_INTERVIEW: 'Interview annehmen',
            DECLINE_INTERVIEW: 'Interview ablehnen',
            ADD_EXPERIENCE: 'Erfahrung hinzufügen',
            DELETE_EXPERIENCE: 'Erfahrung löschen',
            ADD_LANGUAGE: 'Sprache hinzufügen',
            DELETE_LANGUAGE: 'Sprache löschen',
            ADD_BENEFITS: 'Vorteil hinzufügen',
            DELETE_BENEFITS: 'Vorteile löschen',
            UNPUBLISH_JO: "Veröffentlichung des Jobs rückgängig machen",
            PUBLISH_JO: "Auftrag veröffentlichen",
        },
        COMMUN: {
            SURE_DECLINE_MATCH: 'Bestätigen Sie, dass Sie das Match ablehnen möchten.',
            CANT_BLANK :"darf nicht leer sein",
            FINISH_ORDER: 'Sind Sie sicher, dass Sie diese Bestellung abschließen möchten?',
            CURRENCY:'Währung' ,
            COLOR : 'Farbe',
            PRICE_BY_COUNTRY : "Preise pro Land",
            CURRENT : 'Aktuell',
            PREVIOUS:'Vorherige',
            SHOW_DOCUMENT :"Dokument ansehen" ,
            STATEE: 'Status' ,
            MY_JOBZIOO: 'Mein Jobzioo',
            EDIT_CV :'Lebenslauf bearbeiten' ,
            RADIUS : 'Umkreis' ,
            EMTY_CITY:'Keine Stadt (Geben Sie 3 Zeichen ein und geben Sie dann ein)  ' ,

            NO_SPECIALTY : "Keine Fachrichtung" ,
            CREATE_NEW_WORKFLOW : "Erstellen Sie einen neuen Arbeitsablauf",
            UPDATE_WORKFLOW : "Arbeitsablauf bearbeiten",
            ///////   ///////   ///////   ///////

            UPLOAD_MEDIA :'Datei hochladen',
            PROGRESS_UPLOAD : 'Wird hochgeladen' ,
            SIGNATURE: 'Unterschrift',
            IMG: 'Bild',
            FEMALE :'Weiblich',
            MALE : 'Männlich',
            SINGLE: 'Ledig',
            MARIED: 'Verheiratet',
            FILE_MAX_SIZE : `Max. Dateigröße 400 MB.` ,


            ///////   ///////   ///////   ///////

            EMPLOYER : "Arbeitgeber" ,
            BROSO_LOG0: 'Logo auswählen',
            BROSO_IMG: 'Bild auswählen',
            BROSO_PRESENTATION: 'Videopräsentation auswählen',
            BROSO_SIGNATURE: 'Unterschrift auswählen',
            BROSO_PHOTO: 'Foto auswählen',
            BROSO_PASSPORT: 'Ausweis/Reisepass auswählen',
            SEE_MORE: 'Siehe mehr',
            VIDEO: 'Video',
            DOWNLOAD_CV: 'Lebenslauf herunterladen',
            MESSAGES: 'Nachricht',
            PRESENTATION_TYPE: 'Max. 400 MB als MP4',
            MAX_SIZE: 'Max. Größe',
            UPLOAD_COMPANY: 'Firmenlogo hochladen',
            UPLOAD_SIGNATURE: 'Unterschrift hochladen',
            UPLOAD_PHOTO: 'Bewerbungsfoto hochladen',
            LOGO_TYPE: 'Ihr Logo muss im Dateiformat PNG oder JPEG sein. ',
            SIGNATURE_TYPE: 'Ihre Unterschrift muss im Dateiformat PNG oder JPEG sein. ',
            PHOTO_TYPE: 'Ihr Foto muss im Dateiformat JPEG oder PNG sein. ',
            UPLOAD_PASSPORT: 'Ausweis/Reisepass hochladen',
            RETURN_LOGIN: 'Login',
            TOTAL_RECRUTMENTS: 'Gesamteinstellung',
            OF: 'Von',
            AT: 'Auf',
            ON: 'An',
            YOUR_EMAIL: 'Bitte geben Sie Ihre E-Mail-Adresse an.',
            SEND: 'Senden',
            FORGET_PASSWORD: 'Passwort vergessen',
            TERMS_CONDITIONS: 'Allgemeine Geschäftsbedingungen',
            YOU_CAN_NOT_USE: 'Bitte akzeptieren Sie die AGBs.',
            APPROVED_LOGG: ` Entschuldigung, Ihr Zugang wird erst in Kürze freigeschaltet. Bitte prüfen Sie hierzu Ihr E-Mail-Postfach.`,
            SURE_DECLINE_FROM_CV: ' Bestätigen Sie, dass sie ablehnen möchten. ',

            THANKS_VERIVICATION: 'Ihr Account wurde freigeschaltet. Danke für Ihre Anmeldung. Willkommen bei Jobzioo. ',
            VERIFICATION_ACCOUNT: 'Anmeldung bestätigen',
            I_ACCEPT_TERMS: 'Ich bestätige hiermit die Allgemeinen Geschäftsbedingungen (AGB).',
            EXIST: 'Storniert',
            SUBMIT_CV: 'Lebenslauf einreichen',
            FAVORIS_JOBS: 'Meine Favoriten',
            MATCH_JOBS: 'Meine Matches',
            APPLY_JOBS: 'Meine Stellengesuche',
            APPLY_JOB: 'Für ein Stellenangebot bewerben.',
            SUBJECT: 'Thema',
            CONTUNUE: 'Fortführen',
            BROSE: 'Durchsuchen',
            SELECT_JOB_OFFER: 'Ein Stellenangebot auswählen.',
            FINISH: 'Beenden',
            CHECK_YOUR_APPOINTMENT: 'Wir möchten Sie gerne persönlich kennenlernen. Vereinbaren Sie bitte hierzu mit uns einen Termin, damit wir Sie endgültig freischalten können.',
            CHECK: 'Prüfen',
            UPLOAD: 'Hochladen',
            UPLOAD_DOCUMENTS: 'Dokumente hochladen',
            UPLOAD_DOCUMENT: 'Dokument hochladen',
            CHANGE_ATTACHMENT: 'Anhang ändern',
            DASHBOARD: 'Dashboard',
            SELCTE_INTERRVIEW_DATE: 'Wählen Sie einen Termin für ein Vorstellungsgespräch.',
            SUR_PUBLISH: 'Bestätigen Sie, dass sie das Stellenangebot veröffentlichen möchten. ',
            SURE_PORCHASE_ORDER: 'Meinen Kauf bestätigen.',
            SURE_APPLIED_JOB: 'Meine Bewerbung bestätigen.',
            SURE_CANCEL_APPLICATION: 'Bestätigen Sie, dass sie die Anwendung stornieren möchten. ',
            SURE_ACCEPT_PROPOSITION: 'Bestätigen Sie die Annahme dieses Angebots.',
            SURE_DECLINE_PROPOSITION: 'Bestätigen Sie die Ablehnung dieses Angebots.',
            SURE_DECLINE_INTERVIEWD: 'Bestätigen Sie, dass Sie dieses Interview ablehnen möchten. ',
            SURE_ACCEPT_INTERVIEWD: 'Bestätigen Sie, dass Sie dieses Interview annehmen möchten. ',
            SURE_CONFIRM_DOCUMENT: 'Bestätigen Sie dieses Dokument.',
            SURE_DELETE_DOCUMENT: 'Bestätigen Sie, dass sie das Dokument löschen möchten.',
            SURE_CANCEL_INVITATION: 'Bestätigen Sie, dass sie diese Einladung absagen möchten.  ',
            SURE_CANCEL_ORDER: 'Bestätigen Sie, dass Sie diese Einladung stornieren möchten. ',
            SURE_ACCEPT_APPLICATION: 'Bestätigen Sie, dass Sie diese Bewerbung annehmen möchten. ',
            SURE_DECLINE_APPLICATION: 'Nennen Sie uns bitte den Ablehnungsgrund. ',
            SURE_RESET_PASSWORD: 'Bestätigen Sie, dass sie Ihr Passwort zurücksetzen möchten. ',
            SUR_UNPUBLISH: 'Bestätigen Sie, dass sie das Stellenangebot nicht veröffentlichen möchten. ',
            SURE_CANCEL_JOB: 'Bestätigen Sie, dass Sie dieses Stellenagebot löschen möchten. ',
            SURE_EXPIRED_JOB: 'Bestätigen Sie, dass dieses Stellenangebot abgelaufen ist. ',
            SURE_ACCEPT_INVITATION: 'Bestätigen Sie, dass sie die Einladung annehmen möchten. ',
            SURE_RESET: 'Bestätigen Sie, dass sie Ihr Passwort zurücksetzen möchten.',
            SELECT_CANDIDATE: 'Bewerber auswählen',
            SELECT_NAME: 'Suchen',
            DOCUEMNT_BY_CANDIDATE: 'Unterlagen, die der Bewerber zur Verfügung gestellt hat. ',
            DOCUEMNT_BY_PARTNER: 'Unterlagen, die uns der Partner zur Verfügung gestellt hat.',
            ADDWORKFLOW: 'Fügen Sie einen neuen Arbeitsablauf hinzu.',
            HI: 'Hallo',
            TITLE: 'Titel',
            THE_COMMENTS: 'Kommentare',
            MEET_WITH: 'Termin mit',
            ALIAS: 'Alias',
            ALIAS_JOBOFFRE: 'Alias Jobangebot',
            INVITEDBY: 'Eingeladen von',
            APPLIED_JOBS: 'Beworbene Stellen',
            ALIAS_PARTNER: 'Alias Partner',
            CHOOSE_DATE: 'Wählen Sie ein Datum aus.',
            ALIAS_CANDIDATE: 'Alias Kandidat',
            YOU_HAVE_WORK: "Es gibt viel Arbeit heute! Lassen Sie uns anfangen.",
            YOU_HAVE_NOTI1: "Sie haben",
            YOU_HAVE_NOTI2: "Neue Benachrichtigungen",
            FILL: 'Meinen Lebenslauf erstellen',
            FILL_PLEASE: 'Lassen Sie uns mit Ihrem Lebenslauf beginnen und vergessen Sie nicht, Ihre detaillierten Angaben hinzuzufügen.',
            NEW_PARTNERS: 'Neue Partner',
            NEW_JOBS: 'Neue Stellenangebote',
            CREATED_AT: 'Erstellt von',
            NEW_CANDIDATE: 'Neue Bewerber',
            NEW_RECRUTMENT: 'Neue Rekrutierung',
            NEEDS_BE_VIEWD: 'Überprüfen',
            TOTAL_PARTNER: 'Alle Partner',
            TOTAL_JOBS: 'Alle Stellen',
            TOTAL_JOBS_OFFRES: 'Alle Stellenangebote',
            TOTAL_INTERVIEWD: 'Alle Vorstellungsgespräche',
            TOTAL_PUBLISHED_JOBS: 'Alle veröffentlichten Stellen',
            FAVORIS_JOB: 'Meine Favoriten',
            APPLIED_JOB: 'Meine Bewerbung bestätigen',
            PROPOSED_JOBS: 'Vorgeschlagene Stellen',
            TOTAL_MATCHED_JOB: 'Passende Stellenangebote',
            TOTAL_PENDING_JOBS: 'Alle ausstehenden Stellen',
            TOTAL_APPLIED: 'Bewerbungen insgesamt',
            TOTAL_MATCHED: 'Alle passenden Profile',
            TOTAL_CANDIDATE: 'Alle Bewerber ',
            INCOMMING_MEETING: 'Bevorstehendes Meeting',
            VIEW_ALL: 'Alle ansehen',
            PUBLISH: 'Veröffentlichte Stellenangebote',
            UNPUBLISH: 'Unveröffentlichte Stellenangebote',
            APPOINTMENT: 'Termin',
            FINISHED: 'Beenden',
            DATE: 'Eingereichtes Datum',
            STATELABELL: 'Label angeben',
            CONFIRM: 'Bestätigen',
            PRIVATE: 'Privat',
            SHORTCODE: 'Kurzer Code',
            CREATE_ITEM: 'Datei erstellen',
            UPDATE_ITEM: 'Datei aktualisieren',
            MAKEARAISON: 'Ablehnungsgrund',
            RAISON: 'Gründe ansehen',
            MAKEACOMMENTARY: 'Kommentar hinzufügen',
            SEARCH: 'Berufsbezeichnung',
            ACCEPT: 'Akzeptieren',
            DECLINE: 'Zurückgehen',
            ACCEPT_CV: 'Lebenslauf akzeptieren',
            DECLINE_CV: 'Lebenslauf ablehnen',
            APPROVED: 'Anwendungen',
            APPROVE: 'Genehmigen',
            BAN: 'Sperren',
            CHECKED: 'Überprüft',
            DECLINED: 'Abgelehnt',
            CANCELED : 'Abgesagt',
            PENDING : 'Steht aus',
            UPDATE: 'Aktualisieren',
            DELETE: 'Löschen',
            ADD: 'Hinzufügen',
            AGE: 'Alter',
            FIRSTNAME: 'Vorname',
            LASTNAME: 'Nachname',
            FULLNAME: 'Vollständiger Name',
            MAIL: 'E-Mail-Adresse (Firma)',
            MAIL_PARTNER: 'E-Mail-Adresse Partner',
            ADDRESS: 'Straße',
            CITY: 'Stadt',
            COUNTRY: 'Land',
            YEARS: 'Jahr',
            SUBMITTEDDATE: 'Eingegangen am',
            ZIPCODE: 'Postleitzahl',
            ACTIVE: 'Einschalten',
            DESACTIVE: 'Deaktivieren',
            LOGO: 'Logo',
            LOGO_UPLOAD: 'Logo hochladen',
            LOGIN: 'Anmeldung',
            REGISTER: 'Registrieren',
            CREATE_ACCOUNT: 'Konto erstellen',
            PASSWORD: 'Passwort',
            CONFIRM_PASSWORD: 'Passwort bestätigen',
            PHONE: 'Telefon',
            SPECIALITY: 'Fachgebiet',
            Departement: 'Abteilung',
            CREATEDAT: 'Erstellt am: ',
            EDIT: 'Bearbeiten',
            SAVE: 'Speichern',
            UPDATE_PROOF: 'Nachweis aktualisieren',
            IDENTITY_VALIDATION: 'Identitätsprüfung',
            NEW: 'Neu',
            AGENCY: 'Agentur',
            ACCEPTED: 'AKZEPTIERT',
            GROUP: 'Gruppe',
            PROFESSION: 'Berufliche Informationen',
            GENRALINFO: 'Allgemeine Inormationen',
            PASSPORT: 'Reisepass',
            Email: 'E-Mail-Adresse',
            Phone: 'Telefonnummer',
            PHOTO: 'Profilbild',
            address: 'Straße',
            Contact: 'Kontakt',
            DOMAINE: 'Beruf',
            NATIONALITY: 'Nationalität',
            GENDER: 'Geschlecht',
            FULL_NAME: 'Vollständiger Name',
            GENERAL_INFO: 'Allgemeine Informationen  ',
            ExeperiencesEducation: 'Beruflicher Werdegang',
            Languages: 'Sprachen',
            JOBTYPE: 'Beschäftigungsart ',
            ADDTEAM: 'Team hinzufügen',
            EDITTEAM: 'Team bearbeiten',
            CONTACT: 'Kontakt',
            CLOSE: 'Schließen',
            COMMENT: 'Kommentar hinzufügen',
            YES: 'JA, ich nehme die Bewerbung an.',
            NO: 'NEIN, ich möchte den Bewerber nicht. ',
            DEPARTMENT: 'Abteilung',
            GET_COMMENTS: 'Kommentare erhalten',
            MEDIA: 'Dateien',
            HISTORY: ' Berwerberhistorie',
            DATE_BEGIN: 'Anfangsdatum',
            DATE_END: 'Enddatum',
            NUMBER_PRODUCT: 'Produktnummer ',
            CANCEL: "Stornieren",
            LABELL: "Label",
            STATE: "Status",
            DATE_VALIDITY: "Gültigkeitsdatum",
            STATUS: "Status",
            POSITION: "Position",
            COMPANY: "Unternehmensname",
            DOCUMENTS: "Dokumente",
            ERROR_AUTH:'Benutzername oder Passwort sind ungültig. ',

            STEPS: "Schritte",
            OTHERS: 'Anderes',
            TOTALMATCH: 'alle Matchs',
            TOTALPUBLISH: 'Alle veröffentlichten Angebote',
            SEND_EMAIL: 'E-Mail senden',
            SHEDLE_MEETING: 'Meeting planen',
            NEWUSER: 'Neuen Benutzer erstellen',
            VIEW_CV: 'Lebenslauf ansehen',
            VIEW_VEDIO: 'Video ansehen',
            ATTACHEMENTS: 'Anhang',
            PARAGRAPHE: 'Zukünftige Bewerber verbinden',
            NEWTEAM: 'Neue Team',
            CHOOSELOGO: 'Logo auswählen',
            CHOOSEFLAG: 'Flagge auswählen',
            CHOOSESIGNATURE: 'Unterschrift auswählen',
            CHOOSEPHOTO: 'Bewerbungsfoto auswählen',
            CHOOSEPRESENTATION: 'Präsentation hochladen',
            CHOOSEPASSPORT: 'Reisepass hochladen',
            BIRTHDAY: 'Geburtstag',
            AGENCYNAME: 'Agentur',
            OPTIONS: 'Optionen',
            RESET: 'Passwort zurücksetzen',
            OK: 'Ok',
            PRESENTATION: 'Präsentation',
            MARITALSTATUS: 'Familienstand',
            SHOWPROOF: 'Bitte Nachweis hochladen',
            INVITATION: 'Einladungen',
            PROPOSITION: 'Vorschlag',
            APPLICATION: 'Bewerbungen',
            INTERVIEWD: 'Interviewt',
            MATCHED: 'Match',
            SURE_ABLE: 'Bestätigen Sie, dass Sie diese Funktion aktivieren möchten. ',
            SURE_INABLE: 'Bestätigen Sie, dass Sie diese Funktion deaktivieren möchten. ',
            SHOW_APPOINMENT: 'Termin anzeigen',
            SURE_ACCEPT_CV_OF: 'Bestätigen Sie, dass Sie diesen Lebenslauf annehmen wollen.',
            SURE_DECLINE_CV_OF: 'Bestätigen Sie, dass Sie diesen Lebenslauf ablehnen wollen.',
            SURE_CANCEL_PROPOSE: 'Bestätigen Sie, dass Sie diesen Vorschlag stornieren möchten.',
            SURE_FINISH_STEP: 'Bestätigen Sie, dass Sie diesen Schritt beenden möchten.',
            OBJECT_NOT_FOUND: 'Objekt nicht gefunden',
            PARTNER_BANNED_REASONS: 'Dieser Partner ist aus den folgenden Gründen gesperrt.',
            CANDIDATE_BANNED_REASONS: 'Dieser Bewerber wurde aus folgenden Gründen abgelehnt.',
            APPROVED_PARTNERS: 'Zugelassene Partner',
            T0_APPROVE_PARTNERS: 'Partner zulassen',
            PUBLISH_JOB: 'Veröffentlichte Stellen',

            TO_APPROVE_PARTNERS: 'Partner zulassen',

            CANT_CECYLCE: `Bereits verwendet`,
            PROBLEM_NEWORK : 'Es ist ein Problem aufgetreten, bitte versuchen Sie es später erneut!' ,

            SURE_DISABLE_FROM_TEAM: ' Sind Sie sich sicher, dass Sie dieses Team deaktivieren möchten?',
            SURE_ABLE_FROM_TEAM: ' Sind Sie sich sicher, dass Sie dieses Team aktivieren möchten? ',
            SURE_ACCCEPT_TO_CV: 'Sind Sie sicher, dass Sie dies im Lebenslauf angeben möchten?',
            SURE_BAN_IT: 'Sind Sie sich sicher, dass Sie diesen Partner sperren möchten?',
            SURE_APPROVE_IT: 'Sind Sie sich sicher, dass Sie diesen Partner anehmen möchten?',
            SURE_DISABLE_IT: 'Sind Sie sich sicher, dass Sie diese Gruppe deaktivieren möchten?',
            SURE_ABLE_IT: 'Sind Sie sich sicher, dass Sie diese Gruppe aktivieren möchten?',
            SURE_DECLINE_CV: 'Sind Sie sich sicher, dass Sie den Lebenslauf ablehnen möchten?',
            SURE_ACCEPT_CV: 'Sind Sie sich sicher, dass Sie den Lebenslauf akzeptieren möchten?',
            ADD_COMMENT: 'Kommentar hinzufügen',
            ADD_PRICE_BY_COUNTRY: 'Preis je nach Land hinzufügen',
            LIST_PRICE_BY_COUNTRY: 'Preis je nach Land festsetzen ',
            PRICE: 'Preis',

            APPOIEMENT_DATE: 'Termin',

            ADD_STEP: 'Neuen Vorgang hinzufügen',
            UPDATE_STEP: 'Vorgang aktualisieren',

            DECLINE_PROOF: 'Bestätigen Sie, dass Sie diesen Prüfvorgang ablehnen möchten. ',
            ACCEPT_PROOF: 'Bestätigen Sie, dass Sie diesen Prüfvorgang akzeptieren möchten. ',


            WORKFLOW: 'Arbeitsablauf',

            READ_MORE: 'Lesen Sie mehr',

            BROSO_LOGO: 'Logo auswählen',


            RESOLUTION: 'Auflösung',

            YOU_HAVNT_JOB: ' Keine Stelle verfügbar, um Bewerber einzuladen.',
            OPS: 'Ops',
            ADD_JOB: 'Stelle hinzufügen',



            BY : 'Von' ,


        },
        JOBOFFERS: {
            SAVE_AS_DRAFT: 'Als Entwurf speichern',
            JOBINFO: 'Stellenangebotsinformationen',
            REQUIRMENTS: 'Anforderungen',
            OPTIONS: 'Optionen',
            ADD_NEW: 'Neues Stellenangebot hinzufügen',
            JOBOFFER: 'Stellenangebot  ',
            JOBOFFERS: 'Stellenangebote ',
            JOBTITLE: 'Titel',
            JOBTYPELABEL: 'Berufsbezeichnung',
            CREATEDBY: 'Erstellt von',
            CREATEDAT: 'Erstellt am',
            DOMAINLABEL: 'Beschäftigungsart',
            RECOGNITION: 'Berufsanerkennung',
            DESCRIPTION: 'Beschreibung',
            POSITION: 'Position',
            SALARYRANGE: 'Gehaltsspanne',
            DURATION: 'Dauer',
            WORKTIME: 'Beschäftigungsart',
            STATUS: 'Status',
            ZIPCODE: 'Postleitzahl',
            ADD: 'Hinzufügen',
            CREEATEDAT: 'Erstellt am',

            CITYLABEL: 'Stadt',
            PARTNERCREATOR: 'Erstellt von',
            BACKUPDATENAME: 'Update',
            BENIFITS: ' Vorteile',
            BENIFITSLIST: 'Deine Vorteile',
            EXPERIENCES: 'Berufserfahrung',
            LANGUAGES: 'Sprachen',
            DEGREE: '*Abschluss',
            DRAFT: 'Entwurf',
            PENDING: 'Steht aus',
            PUBLISHED: 'Veröffentlicht',
            EXPIRED: 'Abgelaufen',
            CANCELED: 'Abgesagt',
            UNPUBLISH: 'Unveröffentlicht',
            AT_LEAST_THREE: 'Nennen Sie mindestens 3 Vorteile',
            NEXT: 'Nächster Schritt',
            PREV: 'Vorheriger Schritt',
            PUBLISH_JOB: 'Stellenangebot veröffentlichen ',



        },
        PARTNERS: {
            USERS: 'Team',
            PARTNERS: 'Partner',
            BANNED: 'Gesperrte Partner',
            JOBOFFERS: 'Stellenangebote',
            COMPANY: 'Firma',
            POSITION: 'Position',
            REGISTRY: 'Handelregisternummer (falls vorhanden)',
            PHONE: 'Telefonnummer',
            NEW: 'Neu',
            APPROVED: 'Zugelassen',
            TO_APPROVE: 'Bestätigung ausstehend ',
            DECLINED: 'Abgelehnt',
            EMPLYEERANGE: 'Anzahl der Mitarbeiter',
            EMPLOYEERANGE: 'Anzahl der Mitarbeiter',
            COMPNAY_INFO: 'Informationen über das Unternehmen ',
            EDIT: 'Löschen',
            DOMAIN: 'Berufsbezeichnung',
            ACCEPTED: 'Akzeptiert',
            PARTNER: 'Partner',
            RECRUTMENT_CANDIDATES: 'Bewerber für Rekrutierung',
            RAISONS_BANNED: 'Gründe für die Sperre',
            COMPANY_INFO: 'Informationen über das Unternehmen ',
            DETAILS: 'Persönliche Angaben',


        },
        GROUPS: {
            GROUPS: 'Gruppe',
            ROLES: 'Rollen',
            ROLE: 'Rolle',
            CODE: 'Code',
            NEWGROUP: "Neue Gruppe erstellen",
            EDITGROUP: "Gruppe löschen"
        },

        SETTINGS: {
            ADD_TRANSLATE: 'Übersetzung hinzufügen',
            WORD: 'Wort',
            ALMAND: "Deutsch",
            ENGLISH: "Englisch",
            FRENSH: "Französisch",
            WORDING: 'Formulierung',
            TERMS_CONDITIONS: 'Allgemeine Geschäftsbedinungen',
            ADD_SERVICE_TERMS: 'Allgemeine Verkaufsbedingungen',
            SETTINGS: 'Einstellungen',
            DOMAIN: 'Domänenliste',
            AGENCY: 'Agenturenübersicht',
            LANGUAGES: 'Sprachkenntnisse',
            JOBTYPE: 'Berufsübersicht',
            DEGREE: 'Abschlussübersicht',
            EXPERIENCE: 'Erfahrungsübersicht',
            COUNTRIES: 'Länderübersicht',
            CITIES: 'Städteübersicht',
            DOMAINS: 'Berufsbezeichnung ',
            SUBDOMAINS: 'Subdomäne',
            SKILLS: 'Besonderheiten',
            UPDATE_COUNTRY: 'Land aktualisieren',
            ADD_COUNTRY: 'Land hinzufügen',
            UPDATE_CITY: 'Stadt aktualisieren',
            ADD_CITY: 'Stadt hinzufügen',
            UPDATE_JOBTYPE: 'Stelle aktualisieren',
            ADD_JOBTYPE: 'Stelle hinzufügen',
            UPDATE_AGENCY: 'Agentur aktualisieren',
            ADD_AGENCY: 'Agentur hinzufügen',
            UPDATE_LANGUAGE: 'Sprache aktualisieren',
            ADD_LANGUAGE: 'Sprache hinzufügen',
            UPDATE_EXPERIENCE: 'Erfahrungen aktualisieren',
            ADD_EXPERIENCE: 'Erfahrungen hinzufügen',
            UPDATE_DEGREE: 'Abschluss aktualisieren',
            ADD_DEGREE: 'Abschluss hinzufügen',
            UPDATE_DOMAIN: 'Domäne aktualisieren',
            ADD_DOMAIN: 'Domäne hinzufügen',
            ADD_SPECIALITY: 'Besonderheiten hinzufügen',
            UPDATE_SPECIALITY: 'Besonderheiten aktualisieren',
            ADD_SUB_DOMAIN: 'Subdomäne hinzufügen',
            UPDATE_SUB_DOMAIN: 'Subdomäne aktualisieren',
            ADD_GENRAL_TERMS: 'Allgemeine Angaben hinzufügen',
            DISTRICTS : 'Stadtteil' ,
            TYPE : 'Typ' ,
            ZIP_CODE : 'Postleitzahl' ,
            FEDERALS : 'Land' ,

        },

        USERS: {
            USERS: 'Team',
        },
        CANDIDATE: {
            TALENTS : "Talente" ,
            MY_CANDIDATES :"Meine Kandidaten",
            RAISONS_DECLINE: 'Gründe für die Ablehnung',
            SEARCH: 'Vorname , Nachname',
            SCHOOL: 'Schule',
            FROM: 'Von',
            TO: 'An ',
            RECRUTMENT_DOCUMENTS: 'Rekrutierungsdokumente',
            DIPLOME: 'Diplom',
            DEGREE: 'Abschluss',
            ACCEPTED: 'Akzeptiert',
            DECLINED: 'Abgelehnt',
            CONDIDATE: 'Kandidat',
            CONDIDATES: 'Kandidaten',
            JOBTYPE: 'Stelle',
            COUNTRY: 'Land',
            CITY: 'Stadt',
            IDENTITY_VALIDATION: 'Identitätsüberprüfung',
            CVVALIDATION: 'Überprüfung des Lebenslaufs',
            NEW: 'Neu',
            PROPOSEJOB: 'Stellenangebot vorschlagen',
            UPLOADIMAGE: 'Bild hochladen',
            UPLOADPASSPORT: 'Reisepass hochladen',
            UPLOADPRESENTATION: 'Videopräsentation hochladen',
            REASON: 'Grund',
            CREATED_BY: 'Erstellt von',
            COMMENTARY: 'Kommentare',
            CREATEDAT: 'Erstellt',
            CVPENDING: 'Lebenslauf steht noch aus',
            CVACCEPTED: 'Lebenslauf akzeptiert',
            USER: 'Nutzer',
            SHORTCODE: 'Kurzbezeichnung ',
            LABEL: 'Label',
            EDITDEGREE: 'Abschluss hinzufügen',
            EDITEXPERIENCE: 'Erfahrungen anpassen',
            AGENCE: 'Agentur',
            ADDNEW: 'Neuen Bewerber hinzufügen',
            CANDIDATE: 'Kandidaten',
            PROFESSION: 'Beruf',
            GENRALINFO: 'Allgemeine Informationen',
            EDUCATIONEXPERIENCES: 'Schulische Ausbildung',
            LANGUAGES: 'Sprachen',
            CERTIFICATIONS: 'Zertifikate',
            WORKEXPERIENCES: 'Berufliche Erfahrungen',
            UPDATE_PROOF: 'Nachweis aktualisieren',
            INTERSHIP: 'Praktika',
            INTERESTS: 'Interessen',
            DRIVERLICENCES: 'Fahrerlaubnis',
            SKILLS: 'Fähigkeiten',
            LANGUAGE: 'Sprachen',
            CERITIFICATION: 'Zertifikate',
            LEVEL: 'Sprachniveau',
            INFO: 'Info',
            CV: 'Lebenslauf',
            CVDRAFT: 'Lebenslaufentwurf',
            CVDECLINED: 'Lebenslauf abgelehnt',
            INVITED: 'Einladungen',
            PROPOSED: 'Vorschläge',
            MATCHED: 'Matches',
            INTERVIEW: 'Vorstellungsgespräche',
            INTERVIEWD: 'Interviewt',
            RECRUTING: 'Rekrutieren',
            RECRUTING_PREPARING : 'Rekrutierung vorbereiten' ,

            PROPOSE: 'Bewerber vorschlagen',
            INVITED_JOBOFFRE: 'Einladung für das Stellenangebot',
            APPLIED_JOBOFFRE: 'Auf Stellenangebot beworben',
            PROPOSED_JOBOFFRE: 'Stellenangebot vorgeschlagen',
            MATCHING_JOBOFFRE: 'Match für Stellenangebot',
            INTERVIEW_JOBOFFRE: 'Interview für Stellenangebot',
            APPLIED: 'Beworben',
            OTHERS: 'Sonstiges',
            RECRUTING_DOCUMENTS: 'Rekrutierungsdokumente',
            MISSION: 'Auftrag',
            TASKS: 'Aufgaben',
            INSTITUE: 'Einrichtung',
            SUBJECT: 'Betreff',
            DONE_ON: 'Durchgeführt am',
            SCORE: 'Ergebnis',

            SELECT_LICENCE_DRIVER: 'Fahrelaubnis auswählen',
            CV_CANDIDATE: 'Mein Lebenslauf',

            ADD_INTEREST: 'Interessen hinzufügen',
            ADD_SKILL: 'Fähigkeiten hinzufügen',

            WISHLIST: 'Wunschliste',

            ALL: 'Alles',
            CANCEL_INVITATION: 'Termin absagen',
            FAVORIS: 'Favoriten',
            INVITED_CANDIDATE: 'Bewerber einladen',
            ACCEPT_MATCH: 'Match akzeptieren',

            ACCEPT_PROPOSITIONS: 'Vorschlag akzeptieren',
            CANCEL_PROPOSITIONS: 'Vorschlag ablehnen',
            CANCEL_INTERVIEW: 'Interview ablehnen',
            ACCEPT_INTERVIEW: 'Interview akzeptieren',

        },


        SERVICES: {
            SERVICE_TERMS: 'Allgemeine Verkaufsbedingungen',
            FINISH:"Bestellung abschließen",
            TOTAL: 'Gesamt',
            UPLOAD_DOCUMENT: 'Dokument hochladen',
            RECIPIENT: 'Empfänger',
            FILE_NAME: 'Dateiname',
            ALL_ORDERS: 'Alle Aufträge',
            NAME_SERVICE: 'Bezeichnung der Dienstleistung',
            SERVICE: 'Dienstleistung',
            SERVICES: 'Dienstleistungen',
            PRODUCTS: 'Produkte',
            PACKS: 'Pakete',
            PRICE: 'Preise',
            DESCRIPTION: "Beschreibung",
            VERSION: 'Version',
            PAID : 'bezahlt',
            //  PACKS : 'Pakete' ,
            PACK: 'Paket',
            //  PRODUCTS : 'Produkte' ,
            PRODUCT: 'Produkt',
            ADD_PRODUCT: 'Produkt hinzufügen',
            ADD_PACK: 'Paket hinzufügen',
            UPDATE_PRODUCT: 'Produkt aktualisieren',
            TERMS_CONDITION: 'Allgemeine Geschäftsbedingungen',
            CUSTOMER_NAME: 'Kundenname',
            ODER_NUMBER: 'Bestellbezeichnung',
            STATUS: 'Status',
        //    COMPELETED: 'abgeschlossen',
            PENDING: 'Ausstehend',
            CANCELED: 'Abgesagt',
            YOUR_CART: 'Dein Warenkorb',
            CART_CONTAINS_OF: 'Warenkorb',
            DOWNLOAD_INVOICE: 'Rechnung abrufen',
            ACCEPT_PROOF: 'Nachweis akzeptieren',
            DECLINE_PROOF: 'Ablehnungsnachweis',
            SHOW_PROOF: 'Nachweis erbringen',
            PAYMENT_HISTORY: 'Zahlungshistorie',
            CANCEL_CHECKOUT: 'Bestellung abbrechen',
            TO_BUY_SERVICE_YOU_SHOULD_ACCEPT_THIS_TERMS: 'Um den Service zu kaufen, müssen Sie diese Bedingungen akzeptieren.',
            BUY: 'Kaufen',
            CHECKOUT: 'Kasse',
            PRODUCTS_OF_PACK: 'Paketprodukte',

            GET_DOCUMENT: 'Dokument aufrufen',

            UPLOAD_PROOF: 'Nachweis hochladen',

            YOUR_CART_CONTAINS: 'Ihr Warenkorb enthält',

            PENDING_PAYMENT : 'Ausstehende Zahlung',
            FINISHED : 'Beenden',
            CANCELED_PAYMENT :   'Abgeschlossen',
            WAIT_PAYMENT :'kein Zahlungseingang ',
            DOCUMENT_SERVICE_UPLOADED :'Dokument hochgeladen' ,
            COMPELETED:'Abgeschlossen' ,
        },

        ORDERS: {
            ORDERS: 'Bestellungen',
            PRICE: 'Preis',
            TOTAL_ORDER: 'gesamte Bestellung',
        },
        WORKFLOWS: {
            WORKFLOWS: 'Arbeitsablauf',
            WORKFLOWS_PREPARING :"Arbeitsablauf vorbereiten",
            WORKFLOWS_RECRUTMENT :"Arbeitsablauf Rekrutierung",
        },
        PAGES: {
            RECRUTING_PREPARING : 'Rekrutierung vorbereiten' ,
            WORKFLOWS_PREPARING :"Arbeitsablauf vorbereiten",

            TERMS: 'Allgemeine Geschäftsbedingungen',
            WORDINGMANAGMENT: 'Wortlautverwaltung',
            ORDER: 'Bestellung',
            TEAM: 'Team',
            PRICES_SERVICE: 'Preisliste Dienstleistungen',
            NOTIFICATIONMANAGMENT: 'Verwaltung von Benachrichtigungen',
            PARTNERMANAGMENT: 'Verwaltung von Partnern',
            USERSMANAGMENT: 'Verwaltung von Usern',
            SETTINGSMANAGMENT: 'Verwaltung der Einstellungen',
            JOBOFFRESMANAGMENT: 'Verwaltung von Stellenangeboten',
            CANDIDATESMANAGMENT: 'Verwaltung der Bewerber',
            TEAMSMANAGMENT: 'Verwaltung der Teams',
            GROUPSMANAGMENT: 'Verwaltung der Gruppen',
            WORKFLOWMANAGMENT: 'Verwaltung vom Arbeitsablauf',
            SERVICEMANAGMENT: 'Verwaltung der Dienstleistungen',
            ORDERSMANAGMENT: 'Verwaltung der Bestellungen',
            MYPROFILE: 'Mein Profil',
            LOGOUT: 'Abmelden',
            READPARTNER: 'Partner',
            ADD_NEW_PARTNER: 'Partner hinzufügen',
            UPDATE_PARTNER: 'Partner aktualisieren',
            READCANDIDATE: 'Bewerber',
            UPDATECANDIDATE: 'Bewerber aktualisieren',
            ADDCANDIDATE: 'Bewerber hinzufügen',
            READCVCANDIDATE: 'Lebenslauf Bewerber',
            READJOBOFFR: 'Stellenangebot',
            UPDATEJOBOFFER: 'Stellenangebot aktualisieren',
            ADD_NEW_JOBOFFER: 'Stellenangebot hinzufügen',
            DASHBOARD: 'Dashboard',
            RECRUTMENTMANAGMENT: 'Rekrutierungsmanagement',
            READRECRUTMENT: 'Rekrutierungsübersicht',
            STEPS: 'Schritte',
            CHECKOUT: 'Kasse',
            MY_CV: 'Mein Lebenslauf',

            NEWUSER: 'User',
            READUSER: 'User',
            UPDATEUSER: 'User',


        }
        ,
        EMPTY: {
            NOTRECRUTMENTSFOUND: 'Keine Einstellung gefunden',
            MAX_LENGTH : 'Maximale Länge' ,

            NO_COMMENTS: 'Keine Kommentare gefunden',
            DONE: 'Erledigt',
            CHECK_YOUR_EMAIL: 'Check die E-Mail',
            CAN_NOT_RECYCLE_PROPOSTION_TO_SAME_CANDIDATE: `Vorschlag kann nicht an denselben Kandidaten weitergegeben werden.`,
            NOTORDERSFOUND: 'Keine Bestellungen gefunden',
            NOTPACKSFOUND: 'Keine Pakete gefunden',
            NOTPRODUCTSFOUND: 'Keine Produkte gefunden',
            NOTGROUPFOUND: 'Keine Gruppen gefunden',
            NOTPRICEFOUND: 'Keine Preise gefunden',
            NOTNOTIFICATIONSFOUND: 'Keine Mitteilungen gefunden',
            EMAIL: 'E-Mail-Adresse ist erforderlich ',
            PASSWORD: 'Passwort ist erforderlich',
            VALIDMAIL: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
            NOTTEAMFOUND: 'Kein Team gefunden',
            NOTPARTNERFOUND: 'Keine Partner gefunden',
            NOTJOBSFOUND: 'Keine Stellenangebote gefunden',
            NOTSTEPFOUND: 'Keine  Schritte gefunden',
            NOTUSERSFOUND: 'Keine Benutzer gefunden',
            NOTCANDIDATESFOUND: 'Keine Bewerber gefunden',
            NOTFOUNDPAGE: 'Entschuldigung, leider konnten wir diese Seite nicht finden.',
            HOME: 'Home',
            GENDER: 'Geschlecht ist erforderlich ',
            FIRSTNAME: 'Vorname ist erforderlich ',
            LASTNAME: 'Nachname ist erforderlich ',
            ADDRESS: 'Adresse ist erforderlich ',
            COUNTRY: 'Land ist erforderlich  ',
            CITY: 'Stadt ist erforderlich  ',
            RECOGNITION: 'Anerkennung ist erforderlich  ',
            PHONE: 'Telefonnummer ist erforderlich  ',
            MAIL: 'E-Mail-Adresse ist erforderlich  ',
            BIRTHDAY: 'Geburtsdatum ist erforderlich ',
            NATIONALITY: 'Nationalität ist erforderlich  ',
            MARITALSTATUS: 'Familienstand ist erforderlich ',
            JOBTYPE: 'Beruf ist erforderlich ',
            DOMAINE: 'Domäne ist erforderlich ',
            THANKS_CHECK_YOUR_EMAIL: 'Vielen Dank für Ihre Anmeldung. Überprüfen Sie Ihren E-Mail-Eingang um Ihr Passwort zu erhalten..',
            WE_WILL_CONTACT_YOU: 'Herzlichen Dank. Wir werden Sie am "DATE" um "TIME" unter Ihrer Rufnummer "phone" kontaktieren..',

            NOTUSERFOUND: 'Kein User gefunden',


            CAN_NOT_RECYCLE_INVITATION_TO_SAME_JOB: `Einladung kann nicht für dasselbe Stellenangebot verwendet werden`,
            CAN_NOT_RECYCLE_AN_APPLICATION: `Bewerbung kann nicht wiederholt werden. `,

            SCHOOL: 'Name der Schule ist erforderlich',
            DIPLOME: 'Diplombezeichnung ist erforderlich',
            FROM: '"ab dem Jahr" ist erforderlich',
            TO: '"bis zum Jahr" ist erforderlich',
            COMPANY: 'Firmenname ist erforderlich',
            MISSION: 'Aufgabenstellung ist erforderlich',
            TASKS: 'Aufgaben sind erforderlich',
            TITLE: 'Titel ist erforderlich',
            SUBJECT: 'Betreff ist erforderlich',
            INSTITUE: 'Einrichtung ist erforderlich',
            SCORE: 'Ergebnis ist erforderlich',
            ON: 'Jahr ist erforderlich',
            LANGUAGE: 'Sprache ist erforderlich ',
            LEVEL: 'Niveaustufe ist erforderlich',
            CERTIFICATION: 'Zertifikate erforderlich',


        }

    }
};


// this almand version