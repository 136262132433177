import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popApi',
  templateUrl: './popApi.component.html',
  styleUrls: ['./popApi.component.scss']
})
export class PopApiComponent implements OnInit {

   
  constructor(
    private dialogRef: MatDialogRef<PopApiComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
  ) {}



  ngOnInit() {
    //////console.log(this.data);
    
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


}
