import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [

    {
        id       : 'dashboard',
        title    : 'partners',
        translate: 'COMMUN.DASHBOARD',
        type     : 'item',
        icon     : './assets/integration/navbar/dashboard.svg',
        url      : '/',

    },
    {
        id       : 'users',
        title    : 'users',
        translate: 'PARTNERS.USERS',
        type     : 'item',
        url      : '/users',
        icon     : './assets/icons/jobzioo-icons/team.svg',


    },

    {
        id       : 'jobOffer',
        title    : 'joboffer',
        translate: 'JOBOFFERS.JOBOFFER',
        type     : 'item',
        icon     : './assets/icons/jobzioo-icons/job-offers.svg',
        url      : '/joboffers',

    },


    {
        id: 'candidate',
        title: 'workflows',
        translate:'CANDIDATE.CONDIDATES',
        type: 'collapsable',
        icon     : './assets/icons/jobzioo-icons/genralInfo.svg',
        classes :'group',
     //   url      : '/workflows',
        children : [
            {
                id   : 'analytics',
                title: 'CANDIDATE.MY_CANDIDATES',
                type : 'item',
                url  : '/candidates' ,
              //  icon     : './assets/icons/jobzioo-icons/genralInfo.svg',
            },
            {
                id   : 'analytics',
                title: 'CANDIDATE.TALENTS',
                type : 'item',
                url  : '/candidates/talents' ,
            //    icon     : './assets/icons/jobzioo-icons/genralInfo.svg',
            },
        ]
    },

    // {
    //     id: 'candidate',
    //     title: 'candidate',
    //     translate: 'CANDIDATE.CONDIDATE',
    //     type     : 'item',
    //     icon     : './assets/icons/jobzioo-icons/genralInfo.svg',
    //     url      : '/candidates',


    // },


    {
        id: 'recruitings',
        title: 'recruitings',
        translate: 'CANDIDATE.RECRUTING',

        icon     : './assets/icons/jobzioo-icons/Experiences-list.svg',
        type: 'item',
        url: '/recrutment',

    },


];
