<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  {{data.message}}
</div>
<div style="float:right;margin:20px;">
    <button mat-raised-button type="button" color="accent" mat-button type="button" value="Confirm" [mat-dialog-close]="true">
        Confirm
</button>
  <input type="button" value="Cancel"  [mat-dialog-close]="false">
</div>
