
       <div class="header background-WhiteGray h-80" fxLayout="row" fxLayoutAlign="start center"
           *ngIf="curruentPage != ''" [ngClass]="{'':  style === 'yellow','dark': style === 'dark'}">
           <div fxLayout="column" fxLayoutAlign="center start">

               <div fxLayout="column  " fxLayoutAlign="center start" class="ml-32">
                   <div fxLayout="row" fxLayoutAlign="start center">
                    <a [routerLink]="['/']" routerLinkActive="router-link-active" >
                        <img class="img1" src="./assets/integration/Desktop9/home.svg" alt="">
                       </a>
                       
                       <div class="icon" > <img  class="img2" src="./assets/integration/Desktop9/angle-right.svg" alt=""></div>
                       <div *ngFor="let interPath of path " class="linkC">
                           <a [routerLink]="interPath.link" class="linkCrub">{{ interPath.name | translate}}</a>
                           <div class="icon" > <img  class="img2" src="./assets/integration/Desktop9/angle-right.svg" alt=""></div>
                        </div>
                        
                       <span class="">{{curruentPage | translate }}</span>
                   </div>
               </div>