import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { AuthService } from 'app/_services/auth.service';
import { AlertifyService } from 'app/_services/alertify.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { UsersService } from 'app/features/integration/usersIntegration/services/users.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalService } from 'app/_services/locale.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];

    // Private
    private _unsubscribeAll: Subject<any>;
    currentUser
    notificationList = []
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */

    animal: string;
    name: string;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private authService: AuthService,
        private alertify: AlertifyService,
        private router: Router,
        public dialog: MatDialog,
        private _userService: UsersService,
        private localService: LocalService ,
    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'fr',
                title: 'Français',
                flag: 'fr'
            },
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'de',
                title: 'German',
                flag: 'al'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    jwtHelper = new JwtHelperService()
    locale = 'en'
    ngOnInit(): void {
        this.locale =  localStorage.getItem('locale');
        if(this.locale) { 
         this.selectedLanguage = this.languages.find(item => item.id === this.locale )
     
         this.localService.setLocal(this.locale);
 
        
         this._translateService.use(this.locale);
 
       }else {
         this.selectedLanguage =    _.find(this.languages, { id: this._translateService.currentLang });
 
       }

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
     //   this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });
        // const jwt =  localStorage.getItem('jwt');
        // if(jwt) {
        //    this. getCurrentUser()
        // }
        const jwt = localStorage.getItem('jwt')
        if (!this.jwtHelper.isTokenExpired(jwt)) {
            this.getCurrentUser()
        } else {
            localStorage.removeItem('jwt');
            localStorage.clear();
        }

    }



    returnDate(date) {
        moment.locale('fr');
        return moment(date).format('ll')
    }

    retunSubjetc(subject) {
        return subject.replace(/(\r\n|\n|\r|,|_)/gm, " ")
    }


    getNotification() {
        this._userService.GetNotification({locale:this.locale}).subscribe(res => {
            this.notificationList = res.results.Records;

        })
    }

    getCurrentUser() {
         this.  getNotification()
        this._userService.GetGeneralInfo().subscribe(data => {
            if (data.status === "success") {

                this.currentUser = data.results.data;

                localStorage.setItem('need_consent', this.currentUser.partner.need_consent);

                if (this.currentUser.partner.need_consent) {
                    this.router.navigate(['/auth/terms-conditions']);
                    return false;
                }



            }
        })

    }


    onImgErrorPassport(event) {
        event.target.src = './assets/icons/jobzioo-icons/no-picture.svg'
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
    }

    /**
     * Set the language
     *
     * @param lang
     */
     setLanguage(lang): void {
        // Set the selected language for the toolbar
      
        
        this.authService.changeLangue({locale :lang.id }).subscribe(res => {
            localStorage.setItem('locale' , lang.id);
            this.selectedLanguage = lang;

               this.localService.setLocal(lang.id);

       
            this._translateService.use(lang.id);
         //  //////console.log(lang);
             this.locale = lang.id

            this.getNotification()
            
        })

        // Use the selected language for translations
   

    }


    logout() {
        this.authService.logout();
        this.router.navigate(['/auth/login']);
        if (this.locale === 'en') {
            this.alertify.warning('goodbye');
        }
        if (this.locale === 'fr') {
            this.alertify.warning('Au revoir');
        }
    }



}
