import {Injectable} from '@angular/core';
import {HttpParams, HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from 'environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        Authorization: localStorage.getItem('jwt')
    })
};

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    private usersUrl = `${environment.apiUrl}frontpartner/users`;
    private user = `${environment.apiUrl}frontpartner/user`;
    private frontpartner = `${environment.apiUrl}frontpartner`;

   // private agenciesChoiceUrl = `${environment.apiUrl}back/agencies`;
  //  private groupsChoiceUrl = `${environment.apiUrl}back/backGroups`;

    constructor(private http: HttpClient) {
    }

    GetUsers(params): Observable<any> {

        return this.http.get<any>(`${this.usersUrl}`, {params});
    }


    GetGeneralInfo() {
        return this.http.get<any>(`${this.frontpartner}/informations`, {});
    
      }

    GetUser(code): Observable<any> {

        return this.http.get<any>(`${this.user}/${code}`, {});
    }


    editLogo(typeDoc ,data) {
        return this.http.post<any>(`${this.frontpartner}/${typeDoc}`, data);
    
      }

    // GetAgenciesUsers(): Observable<any> {
    //     return this.http.get<any>(`${this.agenciesChoiceUrl}/choices`);
    // }

    // GetGroupsChoice(): Observable<any> {
    //     return this.http.get<any>(`${this.groupsChoiceUrl}/choices`);
    // }

    AddUser(user: any): Observable<any> {
        return this.http.post<any>(`${this.user}`, user);
    }


    EditUser(params, code): Observable<any> {
    //    //////console.log(params, code);
        
        return this.http.put<any>(`${this.user}/${code}`, params);
    }


    setStateTeams(code): Observable<any> {
        return this.http.patch<any>(`${this.user}/${code}/state`, {});
    }



    resetPasswordTeams(email): Observable<any> {
        return this.http.patch<any>(`${this.frontpartner}/resetpasswd/${email}`, {});
    }

    editProfile(params) {
        return this.http.put<any>(`${this.frontpartner}/profile`, params);

    }



    GetNotification(params?): Observable<any> {
        return this.http.get<any>(`${this.frontpartner}/notifications`, {params});
    }


}
