
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/internal/operators/tap';
import { Injectable } from '@angular/core';
import {  UpdateSelectedPath } from './path.actions';
import { PathStateModel } from './path-state.model';
import { TablePath } from '@fuse/components/acces-header/table.path';

@State<PathStateModel>({
    name: 'path',
    defaults: {
        pathData :{
            intermediatePaths: [],
            style: "",
            currentpage: ""
        }
    }
    
})
@Injectable()
export class PathState {
    constructor() {
    }

    @Selector()
    static getIntermediatePathsstate(state: PathStateModel) {
        return state.pathData.intermediatePaths;
    }

    @Selector()
    static getCurrentPage(state: PathStateModel) {
        return state.pathData.currentpage;
    }

    @Selector()
    static getStyle(state: PathStateModel) {
        return state.pathData.style;
    }
    @Selector()
    static getSelectedPath(state: PathStateModel) {
        return state.pathData;
    }

    @Action(UpdateSelectedPath)
    UpdateSelectedProfile({ getState, patchState }: StateContext<PathStateModel>, { path }: UpdateSelectedPath) {
         
            const state = getState();
            const data = path;
            return patchState({
                ...state,
                pathData: data as TablePath
            });
    }
}
